<template>
  <main class="notranslate" translate="no">
    <router-view />
  </main>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  background-color: #f7f7f7;
  
  
}

p {
  padding: 6px;
}


</style>